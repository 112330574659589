<template>
  <div>
    <fieldset>
        <div class="form-card">
            <div class="row">
                <div class="col-7">
                    <h2 class="fs-title">{{stepText}}</h2>
                </div>
                <div class="col-5">
                    <h2 class="steps">Step {{step}} - {{maxStep}}</h2>
                </div>
            </div>
            <label class="fieldlabels">Name: *</label>
            <input
              :class="{
                'error': error.isError && (error.name || !$store.state.details.name)
              }"
              type="text"
              v-model="$store.state.details.name"
              placeholder="Your Name"
            />
            <label class="fieldlabels">Address: *</label>
            <textarea
              :class="{
                'error': error.isError && (error.address || !$store.state.details.address)
              }"
              col="2"
              v-model="$store.state.details.address"
              placeholder="Your Address"
            />
            <label class="fieldlabels">Contact No: *</label>
            <input
              :class="{
                'error': error.isError && (error.phone || !$store.state.details.phone)
              }"
              type="text"
              v-model="$store.state.details.phone"
              placeholder="Mobile Number, eg: +8801XXXXXXXX"
            />
            <label class="fieldlabels">Email: *</label>
            <input
              :class="{
                'error': error.isError && (error.email || !$store.state.details.email)
              }"
              type="email"
              v-model="$store.state.details.email"
              placeholder="Your Email"
            />
            <label class="fieldlabels">FB Page</label>
            <input
              :class="{
                'error': error.isError && error.fbPage
              }"
              type="text"
              v-model="$store.state.details.fbPage"
              placeholder="Your Facebook Page"
            />
        </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    maxStep: {
      type: Number,
      default: 3,
    },
    stepText: {
      type: String,
      default: 'Your information',
    },
    error: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="stylus" scoped>

</style>
