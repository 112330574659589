<template>
  <div>
    <div class="overview">
    <div id="overview">
      <h2 class="overview-head">Event Overview</h2>
          <div class="inner-text">
          We believe talents have the right to be
              heard and seen. That&#39;s why, Somoy is
              bringing a platform to give you a
              chance to create your own stage, show
              your videography skills and make a
              name for yourself. This is an
              international event, aiming to reach
              ambitious and talented videographers
              around the world to give them a
              platform to showcase their skills
              through Somoy Tv.
              <br><br>If you are interested in participating
              in the visual storyteller event, register your interest
              before the deadline.
              Make sure to read through the competition rules which include
              the details of individual eligibility.
              If you have further queries you are requested to visit
              <a class="text-white" href="#faq">the FAQ.</a>
          </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="stylus" scoped>
.overview-head
  margin 0 auto
  object-fit contain
  font-size 90px
  font-weight 800
  font-stretch normal
  font-style normal
  line-height 0.48
  letter-spacing normal
  text-align left
  color #ffffff
  padding 50px
  text-align center
  text-transform uppercase
#overview
  padding 171px 10%
  background rgba(0,0,0,.7)
.overview
    background url("~@/assets/films-video.png")
    text-align:center
.inner-text
  font-size 27px
  color #fff
@media screen and (max-width: 766px)
  #overview
    padding 0
    background rgba(0,0,0,0.7)
  .overview-head
    font-size 25px
    padding-top 50px
    text-align center
    padding-bottom 0
  .inner-text
    font-size 20px
    color #fff
    margin-top 20px
    padding 20px
@media screen and (max-width: 575px)
  #overview
    padding 0
    background rgba(0,0,0,0.7)
  .overview-head
    font-size 25px
    padding-top 50px
    text-align center
    padding-bottom 0
  .inner-text
    font-size 15px
    color #fff
    margin-top 20px
    padding 20px

</style>
