<template>
  <div>
    <fieldset>
        <div class="form-card">
            <div class="row">
                <div class="col-7">
                    <h2 class="fs-title">{{stepText}}</h2>
                </div>
                <div class="col-5">
                    <h2 class="steps">Step {{step}} - {{maxStep}}</h2>
                </div>
            </div>
            <div v-if="!captchaToken" class="captcha-container">
              <vue-hcaptcha
                sitekey="8c72efd5-2d44-4d1b-8b40-17a82f0f682a"
                @verify="verifyCaptcha"
              />
            </div>
            <div v-if="captchaToken" class="file-upload-container">
              <file-select
                :isRequired="true"
                :fileNumber="1"
                :uploadUrl="uploadUrl1"
                @fileUpload="getFileUrl"
                @fileRemove="removeFile"
              />
              <file-select
                :fileNumber="2"
                :uploadUrl="uploadUrl2"
                :disabled="!$store.state.video.file1Url"
                @fileUpload="getFileUrl"
                @fileRemove="removeFile"
              />
            </div>
        </div>
    </fieldset>
  </div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import FileSelect from '@/components/registration/FileSelect.vue';

export default {
  data: () => ({
    uploadUrl1: '',
    uploadUrl2: '',
    maxFiles: 2,
    captchaToken: '',
  }),
  components: {
    FileSelect,
    VueHcaptcha,
  },
  props: {
    step: {
      type: Number,
      default: 2,
    },
    maxStep: {
      type: Number,
      default: 3,
    },
    stepText: {
      type: String,
      default: 'Your information',
    },
  },
  methods: {
    async getUploadUrl() {
      try {
        const config = {
          method: 'post',
          url: `${process.env.VUE_APP_API_ENDPOINT}/videoCampaign/getSignedUrl`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            token: this.captchaToken,
          },
        };

        const { data } = await this.axios(config);

        this.uploadUrl1 = data.url1;
        this.uploadUrl2 = data.url2;
      } catch (err) {
        console.error(err);
      }
    },
    getFileUrl({ url, fileNumber }) {
      if (fileNumber === 1) {
        this.$store.state.video.file1Url = url;
      }
      if (fileNumber === 2) {
        this.$store.state.video.file2Url = url;
      }
    },
    removeFile({ fileNumber }) {
      if (fileNumber === 1) {
        this.$store.state.video.file1Url = '';
      }
      if (fileNumber === 2) {
        this.$store.state.video.file2Url = '';
      }
    },
    verifyCaptcha(token) {
      this.captchaToken = token;
      this.getUploadUrl();
    },
  },
};
</script>

<style lang="stylus" scoped>
.hidden
  display none
.captcha-container
  display flex
  align-items center
  justify-content center
</style>
