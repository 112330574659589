<template>
  <div class="file-select">
    <label
      class="form-label"
      :for="'fileUpload'+fileNumber"
    >
      Upload .MP4 File {{fileNumber}} {{ isRequired ? '*' : '(Optional)' }}
    </label>
    <input
      :id="'fileUpload'+fileNumber"
      type="file"
      class="form-control"
      ref="file"
      accept=".mp4"
      :disabled="disabled || progress > 0"
      @change="handleFileSelect()"
    />
    <div v-if="progress > 0 && progress < 100" class="progress">
      <div
        :style="{'width': progress +'%'}"
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
    <br />
    <div
      v-if="file && progress === 100"
      class="btn btn-danger"
      @click="removeFile()"
    >
      Remove
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    file: '',
    progress: 0,
  }),
  props: {
    fileNumber: {
      type: Number,
      default: 0,
    },
    uploadUrl: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleFileSelect() {
      [this.file] = this.$refs.file.files;
      this.uploadFile();
    },
    async uploadFile() {
      try {
        const config = {
          method: 'put',
          url: this.uploadUrl,
          headers: {
            'Content-Type': this.file.type,
          },
          onUploadProgress: (e) => {
            this.progress = parseInt(Math.round((e.loaded / e.total) * 100), 10);
          },
          data: this.file,
        };

        const res = await this.axios(config);

        this.$emit('fileUpload', { url: this.getFileNameFromResponse(res), fileNumber: this.fileNumber });
      } catch (err) {
        console.error(err);
      }
    },
    removeFile() {
      this.file = '';
      this.progress = 0;
      this.$refs.file.value = null;
      this.$emit('removeFile', { fileNumber: this.fileNumber });
    },
    getFileNameFromResponse(response) {
      return response.config.url.replace('https://somoy-video-campaign-pot.s3.ap-southeast-1.amazonaws.com/', '').split('?')[0];
    },
  },
};
</script>

<style lang="stylus" scoped>
.file-select
  border 1px solid #ccc
  border-radius 4px
  padding 10px
  margin-bottom 15px
</style>
