<template>
  <div class="footer">
    &copy; {{currentYear}}, Somoy Media Limited
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
<style lang="stylus" scoped>
.footer
  background #121213
  color #fff
  text-align center
  padding 50px 0
  border-top 1px solid #000
</style>
