<template>
  <div>
    <video
      class="d-block"
      ref="heroVideo"
      src="@/assets/video/campaignvideo.mp4"
      width="100%" loop gitcontrols autoplay muted>
    </video>
    <top-bar />
    <event />
  </div>
</template>

<script>
import TopBar from '@/components/TopBar.vue';
import Event from '@/components/Event.vue';

export default {
  mounted() {
    this.$refs.heroVideo.play();
  },
  components: {
    Event, TopBar,
  },
};
</script>

<style lang="stylus" scoped>
.container-v
  position relative
  overflow hidden
  width 100%
  padding-top 56.25%
.responsive-iframe
  position absolute
  top 0
  left 0
  bottom 0
  right 0
  width 100%
  height 100%
</style>
