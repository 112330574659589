<template>
  <div id="home">
    <video-player />
    <overview />
    <faq />
    <sponsor v-if="false" />
    <registration />
    <bottom-bar />
    <mobile-menu v-if="$store.state.layout.isMobileMenuVisible" />
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer.vue';
import Overview from '@/components/Overview.vue';
import Faq from '@/components/Faq.vue';
import Sponsor from '@/components/Sponsor.vue';
import Registration from '@/components/Registration.vue';
import BottomBar from '@/components/BottomBar.vue';
import MobileMenu from '@/components/MobileMenu.vue';

export default {
  name: 'Home',
  components: {
    Overview, Faq, Sponsor, Registration, BottomBar, VideoPlayer, MobileMenu,
  },
};
</script>

<style lang="stylus">

</style>
