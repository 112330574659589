<template>
  <div>
    <fieldset>
        <div class="form-card">
            <div class="row">
                <div class="col-7">
                    <h2 class="fs-title">{{stepText}}</h2>
                </div>
                <div class="col-5">
                    <h2 class="steps">Step {{step}} - {{maxStep}}</h2>
                </div>
            </div>
            Submission Confirmation
        </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 3,
    },
    maxStep: {
      type: Number,
      default: 3,
    },
    stepText: {
      type: String,
      default: 'Your information',
    },
  },
};
</script>
