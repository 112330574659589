var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fieldset',[_c('div',{staticClass:"form-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-7"},[_c('h2',{staticClass:"fs-title"},[_vm._v(_vm._s(_vm.stepText))])]),_c('div',{staticClass:"col-5"},[_c('h2',{staticClass:"steps"},[_vm._v("Step "+_vm._s(_vm.step)+" - "+_vm._s(_vm.maxStep))])])]),_c('label',{staticClass:"fieldlabels"},[_vm._v("Name: *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$store.state.details.name),expression:"$store.state.details.name"}],class:{
              'error': _vm.error.isError && (_vm.error.name || !_vm.$store.state.details.name)
            },attrs:{"type":"text","placeholder":"Your Name"},domProps:{"value":(_vm.$store.state.details.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$store.state.details, "name", $event.target.value)}}}),_c('label',{staticClass:"fieldlabels"},[_vm._v("Address: *")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$store.state.details.address),expression:"$store.state.details.address"}],class:{
              'error': _vm.error.isError && (_vm.error.address || !_vm.$store.state.details.address)
            },attrs:{"col":"2","placeholder":"Your Address"},domProps:{"value":(_vm.$store.state.details.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$store.state.details, "address", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"fieldlabels"},[_vm._v("Contact No: *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$store.state.details.phone),expression:"$store.state.details.phone"}],class:{
              'error': _vm.error.isError && (_vm.error.phone || !_vm.$store.state.details.phone)
            },attrs:{"type":"text","placeholder":"Mobile Number, eg: +8801XXXXXXXX"},domProps:{"value":(_vm.$store.state.details.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$store.state.details, "phone", $event.target.value)}}}),_c('label',{staticClass:"fieldlabels"},[_vm._v("Email: *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$store.state.details.email),expression:"$store.state.details.email"}],class:{
              'error': _vm.error.isError && (_vm.error.email || !_vm.$store.state.details.email)
            },attrs:{"type":"email","placeholder":"Your Email"},domProps:{"value":(_vm.$store.state.details.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$store.state.details, "email", $event.target.value)}}}),_c('label',{staticClass:"fieldlabels"},[_vm._v("FB Page")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$store.state.details.fbPage),expression:"$store.state.details.fbPage"}],class:{
              'error': _vm.error.isError && _vm.error.fbPage
            },attrs:{"type":"text","placeholder":"Your Facebook Page"},domProps:{"value":(_vm.$store.state.details.fbPage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$store.state.details, "fbPage", $event.target.value)}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }