<template>
  <div>
    <div id="faq" class="faq">
        <div class="container">
        <h2 class="text-center faq-head">
            FAQ
        </h2>
        <h4 class="text-center text-white">find the frequently asked questions</h4>
        <div class="row">
            <div class="col">
                <template v-for="(faq, i) in faqs">
                    <button
                        :key="i"
                        :class="{ 'accordion': true, 'active': i === selectedIndex }"
                        @click="selectedIndex = i"
                    >
                        <span class="rounded-btn">{{i+1}}</span> {{faq.name}}
                    </button>
                    <div
                        :key="'i'+i"
                        :class="{ 'panel': true, 'active': i === selectedIndex }"
                    >
                        <p class="pt-2">{{faq.description}}</p>
                        <ul>
                            <li
                              v-for="(detail,j) in faq.details" :key="'j'+j">
                              {{detail}}
                            </li>
                        </ul>
                        <p></p>
                    </div>
                </template>
            </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedIndex: 0,
    faqs: [
      {
        name: 'Eligibility',
        details: [
          'Anyone can enter the event, except for staff, directors, and employees of Somoy TV, and their respective spouses, parents, siblings, and children',
          'This is an international event. Any individual holding the legal citizenship of any country of the world can participate in this event unless the event rules contradict any country’s legal policies.',
          'Team submission is strictly prohibited from this event',
        ],
      },
      {
        name: 'Language, Length and Format',
        details: [
          'The videos should either be in English or contain accurate English subtitles.',
          'Your video should be a minimum of 20 seconds and a maximum of 15 minutes long.',
        ],
      },
      {
        name: 'Submission Rules',
        description: 'Your video entry should be uploaded to this website after you register yourself with the event. Before submitting the video you are strongly requested to give the following information upon registering:',
        details: [
          'a very brief explanation of what the video is trying to show and the theme of your submitted video',
          'an email address and active, proper contact information where we can contact you.',
        ],
      },
      {
        name: 'Videos must NOT contain',
        details: [
          'Material that violates or infringes on another’s rights including, but not limited to, privacy, publicity, and intellectual property;',
          'A focus on brand names or trademarks;',
          'Copyrighted material (such as music tracks, snippets of copyrighted programs) without necessary authorizations;',
          'Material that is inappropriate, indecent, obscene, hateful, defamatory, slanderous, or libelous;',
          'Material that promotes racism, hatred, or harms against any group or individual or promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;',
          'Material that is unlawful, in violation of, or contrary to the laws or regulations of the participant’s country.',
        ],
      },
      {
        name: 'Amateur videos are welcomed',
        description: 'Entrants may use any type of video device to record videos, but all videos must be filmed in the widescreen or horizontal screen orientation.',
        details: [
          'Videos can be in any style (e.g., testimonial, comedy, drama, documentary, animated, still art, or live-action).',
          'Videos can be in any language but must have English subtitles if not in English.',
          'Videos can show logos, organizations, or institutions if the display is incidental and not promotional.',
          'All decisions as to whether such display rises to the level of promotion will be determined by Sponsor’s staff on a case-by-case basis.',
        ],
      },
      {
        name: 'Winner Notification and Announcement',
        description: 'Winners, as determined by the authorities of Somoy TV, will be notified through e-mail by April 2021, and will be required to respond (as directed) to the notification attempt. The failure to respond timely to the notification may result in elimination from the event.',
      },
      {
        name: 'Copyright & Other rules',
        details: [
          'Somoy TV, sponsors, and those acting with its authority shall have the unrestricted, absolute, perpetual, worldwide right and license to use participant’s name, address, photograph, likeness, voice, biographical and personal background information, statements, and event entry upon communicating with the selected participants for the further steps.',
          'Somoy TV holds the authority to change, modify, disqualifying any candidates with proper reasoning, and cancellation the event at any time.',
          'By participating in the event, you hereby represent and warrant that you have read these Rules and are fully familiar with the necessary regulations to participate in the contest.',
        ],
      },
    ],
  }),
};
</script>

<style lang="stylus" scoped>
.faq
  color #fff
  background #121213
button.accordion
  border-top 1px solid #ff0000
  margin-top 10px
  font-size 20px
  font-weight normal
  font-stretch normal
  font-style normal
  line-height 2
  letter-spacing normal
  text-align left
  color #ffffff
.panel ul li
  color #a1a1a1
  list-style-type none
  padding-left 20px
.panel.active
    display block
.faq-head
  margin 0 auto
  font-size 95px
  font-weight 800
  font-stretch normal
  font-style normal
  line-height 0.42
  letter-spacing normal
  text-align left;
  color #ffffff
  padding 60px
.rounded-btn
  padding 5px 10px
  border-radius 50%
  background #ff0000
  margin-right 15px
  color #fff
@media screen and (max-width: 600px)
  .faq-head
    margin 0 auto
    font-size 25px
    padding-top 50px
</style>
