<template>
  <div id="registration">
    <div class="rg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-center">
        <div class="col-sm-9 col-md-7 col-lg-6 col-xl-5 text-center p-4 mt-3 mb-2 bg-white rounded">
          <div v-if="success" class="card px-0 pt-4 pb-0 mt-3 mb-3">
            <h2 class="heading">Success!</h2>
            <p>Thank you for uploading your video.</p>
          </div>
          <div v-else class="card px-0 pt-4 pb-0 mt-3 mb-3">
              <h2 class="heading">Send you video</h2>
              <p>Fill all form field to go to next step</p>
              <form class="msform">
                  <!-- progressbar -->
                  <ul class="progressbar">
                      <li
                        @click="step === 1"
                        :class="{'active': step === 1}">
                          <strong>Account</strong>
                      </li>
                      <li
                        @click="step === 2"
                        :class="{'active': step === 2}">
                          <strong>Upload</strong>
                      </li>
                      <li
                        @click="step === 3"
                        :class="{'active': step === 3}">
                          <strong>Confirm</strong>
                      </li>
                  </ul>
                  <div class="progress">
                      <div
                        :style="{'width': (100*(step-1)/maxStep) +'%'}"
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      />
                  </div> <br> <!-- fieldsets -->
                  <reg-account
                    v-if="step === 1"
                    :step="1"
                    :maxStep="maxStep"
                    :stepText="stepText"
                    :error="error"
                  />
                  <reg-video
                    v-if="step === 2"
                    :step="2"
                    :maxStep="maxStep"
                    :stepText="stepText"
                  />
                  <reg-confirm
                    v-if="step === 3"
                    :step="3"
                    :maxStep="maxStep"
                    :stepText="stepText"
                  />
                  <div
                    v-if="showError.show"
                    class="alert alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    <h4 class="alert-heading">{{showError.heading}}</h4>
                    <p>{{showError.body}}</p>
                    <button
                      type="button"
                      class="close rgc"
                      data-dismiss="alert"
                      aria-label="Close">
                      <span aria-hidden="true" @click="showError.show = false">&times;</span>
                    </button>
                  </div>
                  <div
                    :class="{ 'next action-button': true, 'loading': loading }"
                    @click="handleNext"
                  >
                    {{ loading ? 'Loading' : step === maxStep ? 'Submit' : 'Next' }}
                  </div>
                  <button-previous
                    :class="{ 'loading': loading }"
                    v-if="step === 2"
                    :step="step"
                    :maxStep="maxStep"
                    @click="handlePrev"
                  />
              </form>
          </div>
      </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import RegAccount from '@/components/registration/RegAccount.vue';
import RegVideo from '@/components/registration/RegVideo.vue';
import RegConfirm from '@/components/registration/RegConfirm.vue';
import ButtonPrevious from '@/components/registration/ButtonPrevious.vue';

export default {
  data: () => ({
    step: 1,
    maxStep: 3,
    showError: {
      show: false,
      heading: '',
      body: '',
    },
    error: {
      isError: false,
      name: false,
      address: false,
      phone: false,
      email: false,
      fbPage: false,
    },
    loading: false,
    success: false,
  }),
  components: {
    RegAccount,
    RegVideo,
    RegConfirm,
    ButtonPrevious,
  },
  computed: {
    stepText() {
      switch (this.step) {
        case 1:
          return 'Give Details';
        case 2:
          return 'Upload Files';
        case 3:
          return 'Confirm Submission';
        default:
          return 'Form';
      }
    },
  },
  methods: {
    async handleNext() {
      if (this.step === 3) {
        await this.submitForm();
        return;
      }
      if (this.loading) {
        return;
      }
      if (!(await this.verifyStepChange(this.step + 1))) {
        return;
      }
      this.step = this.step === this.maxStep ? this.step : this.step + 1;
    },
    async handlePrev() {
      if (this.loading) {
        return;
      }
      if (!(await this.verifyStepChange(this.step - 1))) {
        return;
      }
      this.step -= 1;
    },
    async submitForm() {
      try {
        this.loading = true;
        this.showError.show = false;
        const data = {
          ...this.$store.state.details,
          ...this.$store.state.video,
        };

        const config = {
          method: 'post',
          url: `${process.env.VUE_APP_API_ENDPOINT}/videoCampaign/submisson`,
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        };

        await this.axios(config);
        this.loading = false;
        this.success = true;
      } catch (err) {
        this.showError.show = true;
        this.showError.body = err.message;
        console.error(err);
        this.loading = false;
      }
    },
    async verifyStepChange(nextStep) {
      this.showError = {
        show: false,
        heading: '',
        body: '',
      };
      this.error = {
        isError: false,
        name: false,
        address: false,
        phone: false,
        email: false,
        fbPage: false,
      };
      if (nextStep > this.step) {
        if (nextStep > this.maxStep) {
          return false;
        }
        if (nextStep === 2) {
          if (
            !this.$store.state.details.name
            || !this.$store.state.details.address
            || !this.$store.state.details.phone
            || !this.$store.state.details.email
          ) {
            this.showError.show = true;
            this.error.isError = true;
            this.showError.heading = 'Fill Required Fields';
            this.showError.body = 'All fields are needed';
            return false;
          }

          const data = await this.serverSideValidation();

          if (data.isError) {
            this.error.isError = !!data.keys;
            data.keys.forEach((v) => {
              this.error[v] = true;
            });
            this.showError.show = true;
            this.showError.heading = data.heading;
            this.showError.body = data.message;
            return false;
          }
        } else if (nextStep === 3) {
          if (!this.$store.state.video.file1Url) {
            this.showError.show = true;
            this.showError.heading = 'Please upload file';
            this.showError.body = 'Need to upload atleast 1 video file';
            return false;
          }
        }
        return true;
      }
      if (nextStep < this.step) {
        if (nextStep < 1) {
          return false;
        }
        return true;
      }
      return false;
    },
    async serverSideValidation() {
      this.loading = true;
      try {
        const config = {
          method: 'post',
          url: `${process.env.VUE_APP_API_ENDPOINT}/videoCampaign/isValidDetails`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            name: this.$store.state.details.name,
            address: this.$store.state.details.address,
            phone: this.$store.state.details.phone,
            email: this.$store.state.details.email,
            fbPage: this.$store.state.details.fbPage,
          },
        };

        await this.axios(config);

        this.loading = false;

        return {
          isError: false,
        };
      } catch (err) {
        this.loading = false;
        if (err.response) {
          const { data, status } = err.response;
          if (status === 400) {
            return {
              isError: true,
              keys: data.validation.body.keys,
              heading: 'Validation Error',
              message: data.validation.body.message,
            };
          }
        }

        console.error(err);
        return {
          isError: true,
          heading: 'Server Error',
          message: 'Oops! Something went wrong.',
        };
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
#registration
  background url("~@/assets/overlayed-image.jpg")
.rg
  background rgba(0,0,0,.7)
.action-button:hover,
.action-button:focus,
.action-button
  &.loading
    background-color #333
</style>

<style lang="stylus">
.rgc
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #ddd;
  color: red;
  padding: 0 5px;
.heading
    text-transform uppercase
    color #f04130
    font-weight normal
.msform
    text-align center
    position relative
    margin-top 20px
    fieldset
        background white
        border 0 none
        border-radius 0.5rem
        box-sizing border-box
        width 100%
        margin 0
        padding-bottom 20px
        position relative
        &:not(:first-of-type)
            display none
    .action-button
        width 100px
        background #f04130
        font-weight bold
        color white
        border 0 none
        border-radius 0px
        cursor pointer
        padding 10px 5px
        margin 10px 0px 10px 5px
        float right
    .action-button-previous
        width 100px
        background #616161
        font-weight bold
        color white
        border 0 none
        border-radius 0px
        cursor pointer
        padding 10px 5px
        margin 10px 5px 10px 0px
        float right

.form-card
    text-align left

.msform input,
.msform textarea
    padding 8px 15px 8px 15px
    border 1px solid #ccc
    border-radius 0px
    margin-bottom 25px
    margin-top 2px
    width 100%
    box-sizing border-box
    color #2C3E50
    background-color #ECEFF1
    font-size 16px
    letter-spacing 1px
    &.error
      color #f04130
      border-color #f04130

.msform input:focus,
.msform textarea:focus
    -moz-box-shadow none !important
    -webkit-box-shadow none !important
    box-shadow none !important
    border 1px solid #f04130
    outline-width 0

.msform .action-button:hover,
.msform .action-button:focus
    background-color #b40000

.msform .action-button-previous:hover,
.msform .action-button-previous:focus
    background-color #000000

.card
    z-index 0
    border none
    position relative

.fs-title
    font-size 25px
    color #f04130
    margin-bottom 15px
    font-weight normal
    text-align left

.red-text
    color #f04130
    font-weight normal

.steps
    font-size 25px
    color gray
    margin-bottom 10px
    font-weight normal
    text-align right

.fieldlabels
    color gray
    text-align left

.progressbar
    margin-bottom 30px
    overflow hidden
    display flex
    justify-content center
    align-items center
    color lightgrey
    .active
        background-color #fff
        color #f04130
        border 2px solid #f04130
        border-radius 4px
    li
        list-style-type none
        font-size 15px
        padding 12px 10px
        margin-right 20px
        font-weight 400
.progressbar li.active:before,
.progressbar li.active:after
    background #b40000

.progress
    height 20px

.progress-bar
    background-color #F04130

.fit-image
    width 100%
    object-fit cover
</style>
