<template>
  <div>
    <div class="next action-button" @click="$emit('click')">
       Previous
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   step: {
  //     type: Number,
  //     default: 1,
  //   },
  //   maxStep: {
  //     type: Number,
  //     default: 3,
  //   },
  // },
};
</script>
